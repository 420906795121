<template>
  <div>
    <div>
      <label>{{ nameDirection?'Dirección de trabajo':'Dirección' }}</label>
    </div>
    <el-row
      v-for="(add,index) in addressC "
      :key="index"
      :gutter="20"
    >
      <el-col
        v-for="(myKey,indexx) in add"
        :key="'b'+indexx"
        :xl="{span: 8}"
        :lg="{span: 8}"
        :md="{span: 8}"
        :sm="{span: 24}"
        :xs="{span: 24}"
      >
        <el-form-item
          v-if="isEmpty(address[myKey].base )"
          :label="$t(address[myKey].nombre)"
          :prop="('direccion.'+myKey)"
          :rules="address[myKey].required?[required('')]:[]"
        >
          <el-input
            v-model="direccion[myKey]"
            type="text"
            auto-complete="no"
            :placeholder="$t(address[myKey].nombre)"
            :disabled="isReadOnly(myKey)"
          />
        </el-form-item>
        <el-form-item
          v-else
          :label="$t(address[myKey].nombre)"
          :prop="('direccion.'+myKey)"
          :rules="address[myKey].required?[required('')]:[]"
        >
          <el-select
            :ref="myKey"
            v-model="direccion[myKey]"
            type="text"
            :placeholder="$t(address[myKey].nombre)"
            filterable
            clearable
            auto-complete="no"
            :no-data-text="$t('commons.address.select_previous',{name:$t(address[Object.keys( address )[indexx]].nombre)})"
            :loading="loading[address[myKey].base.toLowerCase()]"
            :disabled="isReadOnly(myKey)?'disabled':false"
            @input="remote(address[myKey],myKey)"
          >
            <el-option
              v-for="item in levels[address[myKey].base.toLowerCase()]"
              :key="item.id"
              :label="item.nombre"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <hr
      v-if="viewlinea"
      class="pz-dotted"
    >
  </div>
</template>

<script>
import {capitalize, isEmpty, objChunk} from "@/commons/utils/functions";
import {required} from "@/commons/utils/Rules";
import {getDepartamentos, getLocalidad, getMunicipios} from "@/routes/api/resources";
import {mapGetters} from "vuex";

export default {
    name: "PzDirection",
    components: {},
    props: {
        value: {
            type: Object,
            default() {
                return {}
            }
        },
        askPostalCode: {type: Boolean, default: false},
        askCountry: {type: Boolean, default: false},
        viewline: {type: Boolean, default: true},
        isOnlyRead: {type: Boolean, default: false},
        nameDirection: {type: Boolean, default: false}
    },
    data() {
        return {
            direccion: {},
            levels: {
                municipio: [],
                localidad: [],
                departamento: [],
            },
            addressC: [],
            loading: {
                municipio: false,
                localidad: false,
                departamento: false,
            },
            rules: {},
            viewlinea:true
        }

    },
    computed: {
        ...mapGetters([
            'address',
        ]),
    },
    watch: {
        value(val) {
            this.setParametersDirection(val)
        },
        direccion(val) {
            this.handleChange(val)
        }
    },
    created() {
       this.$store.dispatch('loader/up', {trigger: this.$options.name});
        this.seta();
        this.setParametersDirection(this.value);
        this.fetchData().finally(() => {
            this.$store.dispatch('loader/down', {trigger: this.$options.name})
        });
    },
    methods: {
        required,
        isEmpty,
        isReadOnly(){
            if(this.isOnlyRead){
                return true;
            }
            return false;
        },
        seta() {
            if(this.viewline)
                this.viewlinea = false

            if (this.askPostalCode) {
                this.address['codigo_postal'] = {nombre: this.$t('commons.address.postal_code'), required: ""}
            }
            let conf = this.address;
            let direccion = [];
            if (!this.askCountry) {
                let self = this;
                direccion = Object.keys(conf).reduce((object, key) => {
                    if (self.address[key].base !== "Paises") {
                        object[key] = self.address[key]
                    }
                    return object
                }, {})
            } else {
                direccion = conf;
            }
            this.addressC = objChunk(direccion, 3);
        },
        handleChange(value) {
            this.setDirectionConfig();
            this.$emit('input', value);
        },
        setDirectionConfig() {
            let self = this;
            let promises = [];
            Object.entries(this.address).forEach(([key, value]) => {
                promises[key] = self.remote(value, key, false);
            });
            return Promise.all(promises);
        },
        setParametersDirection(value) {
            const input = this.direccion;
            if (input === value) return;
            this.direccion = value;
        },
        async fetchData() {
            let deptos = this.getDepartamento();
            return Promise.all([deptos]);
        },
        async remote(value, property) {
            if (!isEmpty(value.hijo)) {
                let child = this.address[value.hijo]
                if (!isEmpty(child.base)) {
                   
                    let function_name = 'get' + capitalize(child.base);
                    if (this.direccion[property]) {
                        return this[function_name](this.direccion[property], value.hijo);
                    }
                }
            }
        },

        async getMunicipio(value) {
            this.loading.municipio = true;
            getMunicipios(value).then((res) => {
                this.levels.municipio = res.data;
            }).catch(() => {
            }).finally(() => {
                this.loading.municipio = false;
            });
        },

        async getLocalidad(value) {
            this.loading.localidad = true;
            getLocalidad(value).then((res) => {
                this.levels.localidad = res.data;
            }).catch(() => {
            }).finally(() => {
                this.loading.localidad = false;
            });
        },
        // eslint-disable-next-line no-unused-vars
        getDepartamento(value = null) {
            this.loading.departamento = true;
            getDepartamentos().then((res) => {
                this.levels.departamento = res.data;
            }).catch(() => {
            }).finally(() => {
                this.loading.departamento = false;
            });
        },

    },
}

</script>

<style scoped>

</style>